import { NgModule } from '@angular/core';
import { NgxViewerDirective } from './ngx-viewer.directive';

@NgModule({
  imports: [
  ],
  declarations: [NgxViewerDirective],
  exports: [NgxViewerDirective]
})
export class NgxViewerModule { }
